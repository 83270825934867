import './followbtn.css';
import { AiOutlineUserAdd } from 'react-icons/ai';

function FollowBtn() {
  return (
    <div className="followbtn">
        <button className="follow-btn"><AiOutlineUserAdd /> Follow</button>
    </div>
  );
}

export default FollowBtn;
