import './chatbox.css';

function ChatBox() {
  return (
    <div className="chatbox">
        <div className="chatbox-img-column">
            <img className="chatbox-user-img" src="https://cdn-icons-png.flaticon.com/128/1154/1154476.png" alt=""/>
        </div>
        <div className="chatbox-info-column">
              <div className="chatbox-user-name">Abdul Akbar <i class="bi bi-patch-check-fill chatbox-verify"></i></div>
              <p className="chatbox-msg-detail">Hello how are you? I would like to schedule a dinner to discuss business.</p>

        </div>
        <div className="chatbox-action-column"><i class="bi bi-dash-circle-dotted"></i></div>
    </div>
  );
}

export default ChatBox;
