import './followers.css';

function Followers() {
  return (
    <div className="followers">
        <div className="followers-num">140K</div>
        <div className="followers-text">Followers</div>
    </div>
  );
}

export default Followers;
