import './input.css';

function Input() {
  return (
    <div className="msg-input">
      <div className="msg-input-elements">
        <input className="msg-input-field" rows="3" />
        <button className="msg-input-button"><i class="bi bi-send"></i> Send</button>
      </div>
      <div className="counter">Select your reponse type. You have <strong>0</strong> of <strong>250</strong> characters left</div>
    </div>
  );
}

export default Input;
