import './body.css';
import Sidebar from './sidebar';
import Display from './display';
import Header from './header';

function Body() {
  return (
    <div className="body" id="main">
      <Sidebar />
      <Display />
    </div>
  );
}

export default Body;
