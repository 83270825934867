import './friends.css';

function Friends() {
  return (
    <div className="friends">
        <div className="friends-num">30</div>
        <div className="friends-text">Friends®</div>
    </div>
  );
}

export default Friends;
