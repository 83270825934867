import './post.css';
import { AiOutlineEye } from 'react-icons/ai';
import { RiChatQuoteLine } from 'react-icons/ri';
import { AiOutlineRetweet } from 'react-icons/ai';
import { BiHeart } from 'react-icons/bi';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { HiAtSymbol } from 'react-icons/hi';


function Post() {
  return (
    <div className="post">
        <div className="post-pic-column">
            <div className=""><img className="post-pic" src="https://cdn-icons-png.flaticon.com/128/1154/1154468.png" alt=""/></div>
        </div>
        <div className="post-content-column">
            <div className="post-top">
                <div className="post-name">Michael Brown<BsFillPatchCheckFill className="post-check" /></div><div className="post-username"><HiAtSymbol />MikeTheCeleb</div><div className="post-date">2d</div><div className="post-menu"><div><BiDotsVerticalRounded className="hover"/></div></div>
            </div>
            <div className="post-mid">Really really LOOOVE this new ChitChat platform!!!</div>
            <div className="post-bottom">
                <div className="stats-div"><AiOutlineEye /> 3</div><div className="quote-div"><RiChatQuoteLine />1</div><div className="repost-div"><AiOutlineRetweet />0</div><div className="like-div"><BiHeart />3</div>
            </div>
        </div>
    </div>
  );
}

export default Post;
