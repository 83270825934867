import './convo.css';
import MessageId from './messageid';
import Selector from './selector';
import Input from './input';
import Messages from './messages';

function Convo() {
  return (
    <div className="convo">
      <MessageId />
      <Messages />
      <Selector />
      <Input />
    </div>
  );
}

export default Convo;
