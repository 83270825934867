import './header.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { BsGear } from 'react-icons/bs';
import { HiMenu } from 'react-icons/hi';

function Header() {


  return (
    <div className="header">
        <div className="header-contents">
        <div className="header-menu"><HiMenu className="menu-icon"
              onClick={() => {
                document.getElementById("main").style.marginLeft = "170px";
                document.getElementById("sidebar").style.zIndex = "1";
              }
        } /></div>
        <div className="header-logo"><Link to="/">ChitChat</Link></div>
        <div className="header-search">
        <div className="header-searchbar">
        <input className="search-input" placeholder="Search people" type="search" id="site-search" name="q" size="0" /><a href="#" className="search-icon"><i className="bi bi-search"></i></a>
        </div></div>
        <div className="header-settings"><BsGear className="" /></div>
        </div>
    </div>
  );
}

export default Header;
