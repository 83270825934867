import './profile.css';
import { AiOutlineHome } from 'react-icons/ai';
import { IoLocationOutline } from 'react-icons/io5';
import { HiOutlineAcademicCap } from 'react-icons/hi';
import { BsBuilding } from 'react-icons/bs';
import { BiTimeFive } from 'react-icons/bi';
import { RiCoinsLine } from 'react-icons/ri';
import { IoStarOutline } from 'react-icons/io5';
import { TfiTimer } from 'react-icons/tfi';
import { CgHashtag } from 'react-icons/cg';
import { BiCommentDetail } from 'react-icons/bi';
import { SlPicture } from 'react-icons/sl';
import { RiVideoDownloadLine } from 'react-icons/ri';
import { RiVideoUploadLine } from 'react-icons/ri';
import { TfiRulerAlt } from 'react-icons/tfi';
import { BsMoonStars } from 'react-icons/bs';
import { BiHeart } from 'react-icons/bi';
import { RiPulseFill } from 'react-icons/ri';
import { AiFillFacebook } from 'react-icons/ai';
import { IoLogoInstagram } from 'react-icons/io';
import { IoLogoTwitter } from 'react-icons/io5';
import { RiSnapchatFill } from 'react-icons/ri';
import { IoLogoTiktok } from 'react-icons/io5';
import { IoLogoLinkedin } from 'react-icons/io5';
import { BsGlobe2 } from 'react-icons/bs';
import { SiJordan } from 'react-icons/si';
import { SiNike } from 'react-icons/si';
import { SiBentley } from 'react-icons/si';

function Profile() {
  return (
    <div className="profile">
        <div className="bio">
            <p>Founder/CEO of ChitChat®/칫챗™ 👌🏽💯!</p>
        </div>
        <div className="profile-sponsors">
            <SiJordan />
        </div>
        <div className="profile-status">
            <div className="profile-status-row">
                <div className="profile-status-field red-icon"><TfiRulerAlt className="personal-icon"/> 6'0"</div><div className="profile-status-field red-icon"><BsMoonStars className="personal-icon"/> Cancer</div><div className="profile-status-field red-icon"><BiHeart className="personal-icon"/> Single</div><div className="profile-status-field red-icon"><RiPulseFill className="personal-icon"/> Straight</div>
            </div>
            <div className="profile-status-row">
                <div className="profile-status-field yellow-icon"><IoStarOutline className="stats-icon profile-icon"/> 5.0 stars</div><div className="profile-status-field yellow-icon"><TfiTimer className="stats-icon"/> 1 hr</div><div className="profile-status-field yellow-icon"><CgHashtag className="stats-icon profile-icon"/> Entrepreneur</div>
            </div>
            <div className="profile-status-row">
                <div className="profile-status-field green-icon"><BiCommentDetail className="price-icon profile-icon"/> $5 Text</div><div className="profile-status-field green-icon"><SlPicture className="price-icon"/> $10 Pic</div><div className="profile-status-field green-icon"><RiVideoDownloadLine className="price-icon"/> $20 Video</div><div className="profile-status-field green-icon"><RiVideoUploadLine className="price-icon"/> $50 Promo</div>
            </div>
            <div className=""></div>
        </div>
        <ul className="profile-list">
            <li className="profile-item"><div className="profile-icon"><IoLocationOutline /></div><div className="profile-text">Lives in</div><div className="profile-info">Seoul, South Korea</div></li>
            <li className="profile-item"><div className="profile-icon"><AiOutlineHome /></div><div className="profile-text">From</div><div className="profile-info">Goldsboro, North Carolina</div></li>
            <li className="profile-item"><div className="profile-icon"><BsBuilding /></div><div className="profile-text">Works at</div><div className="profile-info">United States Air Force</div></li>
            <li className="profile-item"><div className="profile-icon"><RiCoinsLine /></div><div className="profile-text">Salary is above</div><div className="profile-info">$185,000/yr</div></li>
            <li className="profile-item"><div className="profile-icon"><HiOutlineAcademicCap /></div><div className="profile-text">Studied at</div><div className="profile-info">Bellevue University</div></li>
            <li className="profile-item"><div className="profile-icon"><HiOutlineAcademicCap /></div><div className="profile-text">Studied at</div><div className="profile-info">Community College of the Air Force of the Military</div></li>
            <li className="profile-item"><div className="profile-icon"><HiOutlineAcademicCap /></div><div className="profile-text">Went to</div><div className="profile-info">Eastern Wayne High School</div></li>
            <li className="profile-item"><div className="profile-icon"><BiTimeFive /></div><div className="profile-text">Joined</div><div className="profile-info">January 2022</div></li>
            <li className="profile-item"><div className="profile-icon"><AiFillFacebook /></div><div className="profile-info">MikeTheCeleb</div></li>
            <li className="profile-item"><div className="profile-icon"><IoLogoInstagram /></div><div className="profile-info">MikeTheCeleb</div></li>
            <li className="profile-item"><div className="profile-icon"><IoLogoTwitter /></div><div className="profile-info">MikeTheCeleb</div></li>
            <li className="profile-item"><div className="profile-icon"><RiSnapchatFill /></div><div className="profile-info">MikeTheCeleb</div></li>
            <li className="profile-item"><div className="profile-icon"><IoLogoTiktok /></div><div className="profile-info">MikeTheCeleb</div></li>
            <li className="profile-item"><div className="profile-icon"><IoLogoLinkedin /></div><div className="profile-info">MikeTheCeleb</div></li>
            <li className="profile-item"><div className="profile-icon"><BsGlobe2 /></div><div className="profile-info">chit.chat/mike</div></li>
        </ul>
    </div>
  );
}

export default Profile;
