import './locals.css';
import Card from './card';

function Locals() {
  return (
    <div className="locals">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />

    </div>
  );
}

export default Locals;
