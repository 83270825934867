import './friendslist.css';
import Patron from './patron';

function FriendsList() {
  return (
    <div className="friendslist">
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
    </div>
  );
}

export default FriendsList;
