import './chats.css';
import ChatBox from './chatbox';
import NewChatBox from './newchatbox';



function Chats() {
  return (
    <div className="chats">
      <ChatBox />
      <NewChatBox />
      <ChatBox />
      <ChatBox />
      <ChatBox />
      <ChatBox />
      <ChatBox />
      <ChatBox />
      <ChatBox />
    </div>
  );
}

export default Chats;
