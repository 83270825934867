import './friendsbtn.css';
import { IoChevronDownSharp } from 'react-icons/io5';

function FriendsBtn() {
  return (
    <div className="friendsbtn">
        <button className="friends-btn">Friends <IoChevronDownSharp className="down-arrow"/></button>
    </div>
  );
}

export default FriendsBtn;
