import './picture.css';

function Picture() {
  return (
    <div className="picture">
        <img className="pic-post" src="https://pbs.twimg.com/profile_images/1609384215531122688/tx6bKifN_400x400.jpg" />
    </div>
  );
}

export default Picture;
