import './display.css';
import { Route, Routes } from 'react-router-dom'; // eslint-disable-next-line
import Convo from './convo';
import Inbox from './inbox';
import User from './user/user';
import Celebs from './pages/celebs';
import Locals from './pages/locals';
import Dating from './pages/dating';
import Home from './home';
import Audience from './audience/audience';

function Display() {
  return (
    <div className="display">
            <Routes>
                <Route exact path="/" element={ <Home /> } />
                <Route path="/celebs" element={ <Celebs /> } />
                <Route path="/locals" element={ <Locals /> } />
                <Route path="/dating" element={ <Dating /> } />
                <Route path="/user/*" element={ <User /> } />
                <Route path="/audience/*" element={ <Audience /> } />
                <Route path="/inbox" element={ <Inbox /> } />
                <Route path="/inbox/convo" element={ <Convo /> } />
            </Routes>
    </div>
  );
}

export default Display;
