import './celebs.css';
import Card from './card';

function Celebs() {
  return (
    <div className="celebs">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
    </div>
  );
}

export default Celebs;
