import './messages.css';
import ReceivedMessage from './receivedmessage';
import SentMessage from './sentmessage';
import MessageSent from './messagesent';

function Messages() {
  return (
    <div className="messages-layout">
        <ReceivedMessage />
        <SentMessage />
        <ReceivedMessage />
        <SentMessage />
        <ReceivedMessage />
        <SentMessage />
        <ReceivedMessage />
        <SentMessage />
        <ReceivedMessage />
        <SentMessage />
        <ReceivedMessage />
        <SentMessage />
    </div>
  );
}

export default Messages;
