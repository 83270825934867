import './sidebar.css';
import Chats from './chats';
import Header from './header';
import React from 'react';
import { Link } from 'react-router-dom';
import { IoLocationSharp } from 'react-icons/io5';
import { BsFillHeartFill } from 'react-icons/bs';
import { BsFillStarFill } from 'react-icons/bs';
import { BsPersonCircle } from 'react-icons/bs';
import { BsEnvelope } from 'react-icons/bs';
import { BsPeople } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';
import { VscHome } from 'react-icons/vsc';
import { BsBell } from 'react-icons/bs';
import { BsStar } from 'react-icons/bs';

function Sidebar() {
  return (
    <div className="sidebar" id="sidebar">
        <ul className="sidebar-btns" id="ulSidebar">
            <li className="li-sidebar-close" id="sidebarClose"><RxCross1 className="close-icon" onClick={ () => {
               document.getElementById("sidebar").style.zIndex = "-1";
               document.getElementById("main").style.marginLeft = "0";
             }
           } /></li>
            <li><Link to="/"><button className="menu-button"><VscHome className="home-icon btn-icon"/> Home</button></Link></li>
            <li><Link to="/locals"><button className="menu-button locals-btn"><IoLocationSharp className="locals-icon btn-icon" /> Locals</button></Link></li>
            <li><Link to="/celebs"><button className="menu-button celebs-btn"><BsFillStarFill className="menu-star celebs-icon btn-icon" /> Celebs</button></Link></li>
            <li><Link to="/dating"><button className="menu-button dating-btn"><BsFillHeartFill className="dating-icon btn-icon" /> Dating</button></Link></li>
            <li><Link to="/user"><button className="menu-button"><BsPersonCircle className="btn-icon" /> User</button></Link></li>
            <li><Link to="/inbox"><button className="menu-button"><BsEnvelope className="btn-icon" /> Inbox</button></Link></li>
            <li><Link to="/audience/friends"><button className="menu-button"><BsPeople className="friends-icon btn-icon" /> Friends</button></Link></li>
            <li><button className="menu-button"><BsBell className="activity-icon btn-icon" /> Activity</button></li>
            <li><Link to="/audience/ratings"><button className="menu-button"><BsStar className="reviews-icon btn-icon" /> Ratings</button></Link></li>
        </ul>
        <div className="sidebar-info">
            <a href="#"><span className="info-font">Terms</span></a><a href="#"><span className="info-font">Privacy</span></a><a href="#"><span className="info-font">About</span></a>
        </div>
    </div>
  );

}

export default Sidebar;
