import './dating.css';
import Card from './card';

function Dating() {
  return (
    <div className="dating">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
    </div>
  );
}

export default Dating;
