import './feednav.css';
import { MdFormatQuote } from 'react-icons/md';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { BsPerson } from 'react-icons/bs';
import { BsGrid3X3 } from 'react-icons/bs';
import { TfiQuoteLeft } from 'react-icons/tfi';
import { GrTextAlignLeft } from 'react-icons/gr';
import { Link } from 'react-router-dom';

function FeedNav() {
  return (
    <div className="feednav">
      <ul className="ul-feednav">
        <li className=""><Link to="/user"><div className="posts-tab"><GrTextAlignLeft className="posts-icon" /> Posts</div></Link></li>
        <li className=""><Link to="/user/pics"><div className="pics-tab"><BsGrid3X3 /> Pics</div></Link></li>
        <li className=""><Link to="/user/profile"><div className="profile-tab"><BsPerson /> Profile</div></Link></li>
      </ul>
    </div>
  );
}

export default FeedNav;
