import './newchatbox.css';

function NewChatBox() {
  return (
    <div className="newchatbox">
        <div className="newchatbox-img-column">
            <img className="newchatbox-user-img" src="https://cdn-icons-png.flaticon.com/128/1154/1154476.png" alt=""/>
        </div>
        <div className="newchatbox-info-column">
              <div className="newchatbox-user-name">Abdul Akbar <i class="bi bi-patch-check-fill chatbox-verify"></i></div>
              <p className="newchatbox-msg-detail">Hello how are you? I would like to schedule a dinner to discuss business.</p>

        </div>
        <div className="newchatbox-action-column"><i class="bi bi-dash-circle-dotted"></i></div>
    </div>
  );
}

export default NewChatBox;
