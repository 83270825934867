import './followerslist.css';
import Patron from './patron';

function FollowersList() {
  return (
    <div className="followerslist">
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
    </div>
  );
}

export default FollowersList;
