import './audienceselect.css';
import FriendsList from './friendslist';
import { Link } from 'react-router-dom';

function AudienceSelect() {
  return (
    <div className="audienceselect">
        <ul className="ul-audienceselect">
            <li className="li-audienceselect"><Link to="/audience/friends">Friends</Link></li>
            <li className="li-audienceselect"><Link to="/audience/following">Following</Link></li>
            <li className="li-audienceselect"><Link to="/audience/followers">Followers</Link></li>
            <li className="li-audienceselect"><Link to="/audience/ratings">Ratings</Link></li>
        </ul>
    </div>
  );
}

export default AudienceSelect;
