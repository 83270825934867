import './actions.css';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { HiAtSymbol } from 'react-icons/hi';
import { CgHashtag } from 'react-icons/cg';
import FollowBtn from './followbtn';
import MessageBtn from './messagebtn';

function Actions() {
  return (
    <div className="actions">
        <FollowBtn />
        <MessageBtn />
    </div>
  );
}

export default Actions;
