import './messageid.css';

function MessageId() {
  return (
    <div className="messageid">
        <div className="id-img-column">
        <img className="sender-img" src="https://cdn-icons-png.flaticon.com/128/1154/1154476.png" alt=""/>
        </div>
        <div className="id-user-details">
        <div className="sender-name">Abdul Akbar <i class="bi bi-patch-check-fill id-verify"></i></div>
        <div className="sender-username">@abdulakbar</div>
        </div>
        <div className="id-action-column"><i class="bi bi-three-dots-vertical id-action"></i></div>
    </div>
  );
}

export default MessageId;
