import './inbox.css';
import InboxNav from './inboxnav';
import Chats from './chats';

function Inbox() {
  return (
    <div className="inbox">
        <InboxNav />
        <Chats />
    </div>
  );
}

export default Inbox;
