import './followinglist.css';
import Patron from './patron';

function FollowingList() {
  return (
    <div className="followinglist">
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
    </div>
  );
}

export default FollowingList;
