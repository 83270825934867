import './audiencecount.css';
import Friends from './friends';
import Following from './following';
import Followers from './followers';
import Ratings from './ratings';
import { Link } from 'react-router-dom';

function AudienceCount() {
  return (
    <div className="audiencecount">
        <Link to="/audience/friends"><Friends /></Link>
        <Link to="/audience/following"><Following /></Link>
        <Link to="/audience/followers"><Followers /></Link>
        <Link to="/audience/ratings"><Ratings /></Link>
    </div>
  );
}

export default AudienceCount;
