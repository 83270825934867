import './posts.css';
import Post from './post';

function Posts() {
  return (
    <div className="posts">
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
        <Post />
    </div>
  );
}

export default Posts;
