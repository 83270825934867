import './ratings.css';
import { RxStarFilled } from 'react-icons/rx';

function Ratings() {
  return (
    <div className="ratings">
        <div className="ratings-num"><span>5.0</span><RxStarFilled className="rating-star" /></div>
        <div className="ratings-text">Ratings</div>
    </div>
  );
}

export default Ratings;
