import './pics.css';
import Picture from './picture';

function Pics() {
  return (
    <div className="pics">
        <Picture />
        <Picture />
        <Picture />

        <Picture />
        <Picture />
        <Picture />

        <Picture />
        <Picture />
        <Picture />

        <Picture />
        <Picture />
        <Picture />

        <Picture />
        <Picture />
        <Picture />

        <Picture />
        <Picture />
        <Picture />

        <Picture />
        <Picture />
        <Picture />

        <Picture />
        <Picture />
        <Picture />
    </div>
  );
}

export default Pics;
