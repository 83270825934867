import './selector.css';

function Selector() {
  return (
    <div className="selectors">
        <button className="selector-button"><i className="bi bi-chat-left-text selector-icon"></i> Text</button>
        <button className="selector-button"><i className="bi bi-image selector-icon"></i> Pic</button>
        <button className="selector-button"><i className="bi bi-camera-video selector-icon"></i> Video</button>
        <button className="selector-button"><i class="bi bi-camera-reels selector-icon"></i> Promo</button>
        <div className="selector-price"><i className="bi bi-currency-dollar"></i>0</div>
    </div>
  );
}

export default Selector;
