import "./patron.css";
import { BsPatchCheck } from "react-icons/bs";
import { HiOutlineAtSymbol } from "react-icons/hi";
import FriendsBtn from './friendsbtn';

function Patron() {
  return (
    <div className="patron">
      <div className="patron-img">
        <img
          className="patron-img-file"
          src="https://cdn-icons-png.flaticon.com/512/145/145843.png"
          alt="img"
        />
      </div>
      <div className="patron-info">
      <div className="patron-id">
        <div className="patron-name"> Elon Musk </div>
        <BsPatchCheck className="patron-badge-verified" />
      </div>
      <div className="patron-id-2">
        <div className="patron-username">
          <HiOutlineAtSymbol className="patron-at" />
          <span>elon</span>
        </div>
      </div>
      </div>
      <div className="patron-btn"><FriendsBtn /></div>
    </div>
  );
}

export default Patron;
