import './receivedmessage.css';

function ReceivedMessage() {
  return (
    <div className="received-message">
        <div className="r-pic-column">
        <img className="r-userpic" src="https://cdn-icons-png.flaticon.com/128/1154/1154476.png" alt=""/>
        </div>
        <div className="r-details-column">
        <div className="r-text-sent">Hello how are you? I would like to schedule a dinner to discuss business.</div>
        <div className="r-text-details">1 Jan @ 5:39 PM, $5.00 Text, 21 hrs 12 mins left</div>
        </div>
        <div className="r-icon-column">
        <i class="bi bi-currency-dollar"></i>
        </div>
    </div>
  );
}

export default ReceivedMessage;
