import './ratingslist.css';
import Patron from './patron';

function RatingsList() {
  return (
    <div className="ratingslist">
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
          <Patron />
    </div>
  );
}

export default RatingsList;
