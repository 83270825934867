import './following.css';

function Following() {
  return (
    <div className="following">
        <div className="following-num">30</div>
        <div className="following-text">Following</div>
    </div>
  );
}

export default Following;
