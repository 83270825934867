import './inboxnav.css';

function InboxNav() {
  return (
    <div className="inboxnav">
      <ul className="ul-inboxnav">
        <li className="li-inboxnav-inbox"><div className="inbox-tab"><i class="bi bi-envelope-open"></i> Inbox</div></li>
        <li className="li-inboxnav"><div className="general-tab"><i class="bi bi-globe2"></i> General</div></li>
        <li className="li-inboxnav"><div className="archive-tab"><i class="bi bi-archive"></i> Archived</div></li>
      </ul>
    </div>
  );
}

export default InboxNav;
