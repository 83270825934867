import './feed.css';
import { Routes, Route } from 'react-router-dom'; // eslint-disable-next-line
import Posts from './posts';
import Pics from './pics';
import Profile from './profile';

function Feed() {
  return (
    <div className="feed">
        <Routes>
            <Route exact path="/" element={ <Posts /> } />
            <Route path="/pics" element={ <Pics /> } />
            <Route path="/profile" element={ <Profile /> } />
        </Routes>
    </div>
  );
}

export default Feed;
