import './sentmessage.css';

function SentMessage() {
  return (
    <div className="sent-message">
        <div className="s-pic-column">
        <img className="s-userpic" src="https://cdn-icons-png.flaticon.com/512/1163/1163111.png" alt=""/>
        </div>
        <div className="s-details-column">
        <div className="s-text-sent">Ok that's not a problem. Do you have an idea of where you would like to meet?</div>
        <div className="s-text-details"><i class="bi bi-check2-all sent-check"></i> 1 Jan @ 5:39 PM, $5.00 Text, 21 hrs 12 mins left</div>
        </div>
    </div>
  );
}

export default SentMessage;
