import './audience.css';
import { Routes, Route } from 'react-router-dom';
import AudienceSelect from './audienceselect';
import AudienceFeed from './audiencefeed';
import FriendsList from './friendslist';
import FollowingList from './followinglist';
import FollowersList from './followerslist';
import RatingsList from './ratingslist';
import { BsChevronLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Audience() {
  return (
    <div className="audience">
        <div className="audience-header">
            <div className="audience-header-box"><Link to="/user"><div className="audience-header-back"><BsChevronLeft /></div></Link></div>
            <div className="audience-header-box"><span className="audience-header-username">@MikeTheCeleb</span></div>
            <div className="audience-header-box"></div>
        </div>
        <AudienceSelect />
        <AudienceFeed />
    </div>
  );
}

export default Audience;
