import './userid.css';
import AudienceCount from './audiencecount';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { HiAtSymbol } from 'react-icons/hi';
import { CgHashtag } from 'react-icons/cg';

function UserId() {
  return (
    <div className="userid">
      <div className="userid-pic-column">
        <div className="userpic"><img className="userpic-file" src="https://cdn-icons-png.flaticon.com/128/1154/1154468.png" alt="" /></div>
      </div>
      <div className="userid-info-column">
        <div className="name">Michael Brown <BsFillPatchCheckFill className="check" /></div>
        <div className="username"><HiAtSymbol />MikeTheCeleb</div>
        <AudienceCount />
      </div>


    </div>
  );
}

export default UserId;
