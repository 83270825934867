import './user.css';
import UserId from './userid';
import Actions from './actions';
import FeedNav from './feednav';
import Feed from './feed';

function User() {
  return (
    <div className="user">
          <UserId />
          <Actions />
          <FeedNav />
          <Feed />
    </div>
  );
}

export default User;
