import './home.css';
import Post from './user/post';
import Picture from './user/picture';

function Home() {
  return (
    <div className="home">
        <Post />
        <Post />
        <Picture />
        <Post />
        <Picture />
        <Post />
        <Post />
        <Post />
        <Picture />
        <Post />
        <Post />
    </div>
  );

}

export default Home;
