import './messagebtn.css';
import { RxPaperPlane } from 'react-icons/rx';

function MessageBtn() {
  return (
    <div className="messagebtn">
        <button className="message-btn"> Message </button>
    </div>
  );
}

export default MessageBtn;
