import './card.css';
import FollowBtn from '../user/followbtn';
import { HiAtSymbol } from 'react-icons/hi';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { BiDotsHorizontalRounded } from 'react-icons/bi';

function Card() {
  return (
    <div className="card">
        <div><img className="card-pic" src="https://cdn-icons-png.flaticon.com/128/1154/1154468.png" alt="" /></div>
        <div className="name">Michael Brown <BsFillPatchCheckFill className="check" /></div>
        <div className="username"><HiAtSymbol />MikeTheCeleb</div>
        <div className="follow-btn-row"><FollowBtn /></div>
        <div>2m <BiDotsHorizontalRounded className="hover" /></div>
    </div>
  );
}

export default Card;
