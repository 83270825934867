import './audiencefeed.css';
import { Routes, Route } from 'react-router-dom';
import FriendsList from './friendslist';
import FollowingList from './followinglist';
import FollowersList from './followerslist';
import RatingsList from './ratingslist';

function AudienceFeed() {
  return (
    <div className="audiencefeed">
        <Routes>
            <Route exact path="/friends" element={ <FriendsList /> } />
            <Route path="/following" element={ <FollowingList /> } />
            <Route path="/followers" element={ <FollowersList /> } />
            <Route path="/ratings" element={ <RatingsList /> } />
        </Routes>
    </div>
  );
}

export default AudienceFeed;
